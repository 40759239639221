import React, { Component } from "react";
import "./styles/Projects.css";
import HomeColumn from "../components/HomeColumn";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth };
    this.projectsRef = React.createRef();
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    if (this.projectsRef.current !== null) this.setState({ width: this.projectsRef.current.offsetWidth });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    let randomHeights1 = [];
    let randomHeights2 = [];
    for (let i = 0; i < 4; i++) {
      let random = Math.round(Math.random() * 60) + 40;
      randomHeights1.push(random);
      randomHeights2.push(100 - random - 2);
    }

    let projectsDiv;
    console.log(this.state.width);

    if (this.state.width >= 1000) {
      
      projectsDiv = (
        <div className="home-container">
          <div className="home-container-column">
            <HomeColumn
              link={[true, "https://play.google.com/store/apps/details?id=com.orglce.blux&hl=en"]}
              height={randomHeights1[0]}
              title="blux"
              desc="android app used with an arduino powered controller that controls an led strip."
              languages="java"
              completed="ongoing"
              logo="./images/blux.png"
            />
          </div>
          <div className="home-container-column">
            <HomeColumn
              link={[true, "https://noci.imagosloveniae.net/"]}
              height={randomHeights1[2]}
              title="noči v stari ljubljani"
              desc="website for the festival nights in old town ljubljana hosted by imago sloveniae foundtation."
              languages="reactjs"
              completed="2019"
              logo="./images/blux.png"
            />
          </div>
          <div className="home-container-column">
            <HomeColumn
              link={[true, "https://elektro-jani.com/"]}
              height={randomHeights1[3]}
              title="elektro jani"
              desc="website for a small private company."
              languages="js, jquery"
              completed="2015"
              logo="./images/blux.png"
            />
          </div>
        </div>
      );
    } else if (this.state.width < 1000) {
      let mobile = false;
      if (this.state.width < 500)
        mobile = true;
      projectsDiv = (
        <div className="home-grid-container">
          <div>
            <HomeColumn
              link={[true, "https://play.google.com/store/apps/details?id=com.orglce.blux&hl=en"]}
              title="blux"
              desc="android app used with an arduino powered controller that controls an led strip."
              height={mobile ? undefined : 100}
              languages="java"
              completed="ongoing"
              logo="./images/blux.png"
            />
          </div>

          <div>
            <HomeColumn
              link={[true, "https://noci.imagosloveniae.net/"]}
              title="noči v stari ljubljani"
              desc="website for the festival nights in old town ljubljana hosted by imago sloveniae foundtation."
              height={mobile ? undefined : 100}
              languages="reactjs"
              completed="2019"
              logo="./images/blux.png"
            />
          </div>
          <div>
            <HomeColumn
              link={[true, "https://elektro-jani.com/"]}
              title="elektro jani"
              desc="website for a small private company."
              height={mobile ? undefined : 100}
              languages="js, jquery"
              completed="2015"
              logo="./images/blux.png"
            />
          </div>
        </div>
      );
    }

    return (
      <div className="home-main-container" ref={this.projectsRef}>
        {projectsDiv}
      </div>
    );
  }
}

export default Projects;
