import React, { useEffect, useState, useCallback } from "react";
import "../styles/Photography.css";

let whichPhoto = 0;

function Vrhovci() {
  let photos = [];
  const [currentPhoto, setCurrentPhoto] = useState(0);
  let req = require.context("../../../public/images/vrhovci", false, /.*\.jpg$/);
  req.keys().forEach(function(key) {
    photos.push("/images/vrhovci/" + key.substring(2, key.length));
  });

  const switchImage = useCallback(
    code => {
      if (code === 37) whichPhoto--;
      else if (code === 39) whichPhoto++;

      if (whichPhoto === -1) whichPhoto = photos.length - 1;
      else if (whichPhoto === photos.length) whichPhoto = 0;

      setCurrentPhoto(whichPhoto);
    },
    [photos.length]
  );

  useEffect(() => {
    const handleEsc = event => {
      switchImage(event.keyCode);
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [switchImage]);

  return (
    <div className="photography-container">
      <div className="photo-container">
        <img src={photos[currentPhoto]} alt=""></img>
        <div className="photo-mouse-box">
          <div unselectable="on" className="photo-left-box" onClick={() => switchImage(37)}></div>
          <div unselectable="on" className="photo-right-box" onClick={() => switchImage(39)}></div>
        </div>
      </div>
    </div>
  );
}

export default Vrhovci;

