import React from "react";
import "./styles/About.css";

function About() {
  return (
    <div className="about-container">
        <div className="about-name">luka dragar</div>
        <div className="about-contact">
          dragarluka@gmail.com
          <br />
          <a href="https://www.instagram.com/lukadragaar" rel="noopener noreferrer" target="_blank">
            instagram.com/lukadragaar
          </a>
        </div>
        <span>programmer</span> and <span>photographer</span> from slovenia. currently i'm studying computer science and
        mathematics at the university of ljubljana. my programming interests include mostly doing front-end development
        using <span>reactjs</span> and android development with <span>java</span>.
        <br />
        <br />
        besides playing around with computers i also like to photograph things, mainly <span>people</span> and <span>urban areas</span>, because i've always been fascinated by
        cities and their development, especially public transit.
        <br/>
        <br/>
        my favourite genre of music is house, i really like horror films, my favourite book is 1984, i like table tennis and i hate mornings. :)
    </div>
  );
}

export default About;
