import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles/Sidebar.css";

function Sidebar() {
  const [secondSidebarVisible, setSecondSidebarVisible] = useState(false);

  return (
    <div className="sidebar-container">
      <div className="sidebar">
        <Link to="/">
          <div className="sidebar-item">projects</div>
        </Link>
        <div className="sidebar-fake-link">
          <div
            className="sidebar-item"
            onMouseEnter={() => setSecondSidebarVisible(true)}
            onMouseLeave={() => setSecondSidebarVisible(false)}
          >
            photography
          </div>
        </div>
        <Link to="/about">
          <div className="sidebar-item">about</div>
        </Link>
      </div>

      {secondSidebarVisible ? (
        <div
          className="sidebar sidebar-second"
          onMouseEnter={() => setSecondSidebarVisible(true)}
          onMouseLeave={() => setSecondSidebarVisible(false)}
        >
          <Link to="/">
            <div className="sidebar-item invisible">.</div>
          </Link>
          <Link to="/photography/vrhovci" onClick={() => setSecondSidebarVisible(false)}>
            <div className="sidebar-item">vrhovci</div>
          </Link>
          <Link to="/photography/people" onClick={() => setSecondSidebarVisible(false)}>
            <div className="sidebar-item">people</div>
          </Link>
          <Link to="/photography/atms" onClick={() => setSecondSidebarVisible(false)}>
            <div className="sidebar-item">atms</div>
          </Link>
          <Link to="/photography/zagreb" onClick={() => setSecondSidebarVisible(false)}>
            <div className="sidebar-item">zagreb</div>
          </Link>
          <Link to="/photography/ljubljana" onClick={() => setSecondSidebarVisible(false)}>
            <div className="sidebar-item">ljubljana</div>
          </Link>
          <Link to="/photography/landscape" onClick={() => setSecondSidebarVisible(false)}>
            <div className="sidebar-item">landscape</div>
          </Link>
        </div>
      ) : null}
    </div>
  );
}

export default Sidebar;
