import React from "react";
import { Link } from "react-router-dom";
import "./styles/HomeColumn.css";

function HomeColumn(props) {
  return (
    <div
      className={"home-column " + (props.display === undefined ? "" : "invisible")}
      style={{ height: props.height === undefined ? "auto" : props.height + "%" }}
    >
      <div className="column-container">
        <div>
          <div className="column-title">{props.title}</div>
          <div className="column-synopsis">{props.desc}</div>
          <div className="column-data">
            written in: <span>{props.languages}</span>
            <br />
            completed: <span>{props.completed}</span>
          </div>
        </div>
        <div>
          {props.link[0] === true ? (
            <a href={props.link[1]} rel="noopener noreferrer" target="_blank">
              <div className="column-button">view</div>
            </a>
          ) : (
            <Link to={props.link[1]}>
              <div className="column-button">view</div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomeColumn;
