import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles/Topbar.css";

function Topbar() {
  const [topbarExpanded, setTopbarExpanded] = useState(false);

  const toggleTopbar = () => {
    let temp = !topbarExpanded;
    setTopbarExpanded(temp);
  };

  return (
    <div className="topbar-container">
      <div className="topbar-top-container">
        <div className="topbar-menu-icon-container" onClick={()=> toggleTopbar()}>
          <div className="topbar-icon"></div>
        </div>
      </div>
      <div className="topbar-bottom-container" style={{ display: topbarExpanded ? "" : "none" }}>
        <div className="sidebar-container">
          <div className="sidebar">
            <Link to="/">
              <div className="sidebar-item" onClick={()=> toggleTopbar()}>projects</div>
            </Link>
            <div className="sidebar-fake-link">
              <div className="sidebar-item">photography</div>
            </div>
            <div className="topbar-second">
              <Link to="/photography/vrhovci">
                <div className="sidebar-item" onClick={()=> toggleTopbar()}>vrhovci</div>
              </Link>
              <Link to="/photography/people">
                <div className="sidebar-item" onClick={()=> toggleTopbar()}>people</div>
              </Link>
              <Link to="/photography/atms">
                <div className="sidebar-item" onClick={()=> toggleTopbar()}>atms</div>
              </Link>
              <Link to="/photography/zagreb">
                <div className="sidebar-item" onClick={()=> toggleTopbar()}>zagreb</div>
              </Link>
              <Link to="/photography/ljubljana">
                <div className="sidebar-item" onClick={()=> toggleTopbar()}>ljubljana</div>
              </Link>
              <Link to="/photography/landscape">
                <div className="sidebar-item" onClick={()=> toggleTopbar()}>landscape</div>
              </Link>
            </div>
            <Link to="/about">
              <div className="sidebar-item" onClick={()=> toggleTopbar()}>about</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
