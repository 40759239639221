import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Sidebar from "./components/Sidebar";
import Topbar from "./components/Topbar";
import Zagreb from "./pages/photos/Zagreb";
import Atms from "./pages/photos/Atms";
import Ljubljana from "./pages/photos/Ljubljana";
import Vrhovci from "./pages/photos/Vrhovci";
import Landscape from "./pages/photos/Landscape";
import People from "./pages/photos/People";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth };
  }
  componentDidMount() {
    let photos = [];
    let req = require.context("../public/images/vrhovci", false, /.*\.jpg$/);
    req.keys().forEach(function(key) {
      photos.push("/images/vrhovci/" + key.substring(2, key.length));
    });
    req = require.context("../public/images/people", false, /.*\.jpg$/);
    req.keys().forEach(function(key) {
      photos.push("/images/people/" + key.substring(2, key.length));
    });
    req = require.context("../public/images/landscape", false, /.*\.jpg$/);
    req.keys().forEach(function(key) {
      photos.push("/images/landcape/" + key.substring(2, key.length));
    });
    req = require.context("../public/images/atms", false, /.*\.jpg$/);
    req.keys().forEach(function(key) {
      photos.push("/images/atms/" + key.substring(2, key.length));
    });
    req = require.context("../public/images/ljubljana", false, /.*\.jpg$/);
    req.keys().forEach(function(key) {
      photos.push("/images/ljubljana/" + key.substring(2, key.length));
    });
    req = require.context("../public/images/zagreb", false, /.*\.jpg$/);
    req.keys().forEach(function(key) {
      photos.push("/images/zagreb/" + key.substring(2, key.length));
    });

    photos.forEach(picture => {
      new Image().src = picture;
    });
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.setState({ width: window.innerWidth });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  render() {
    return (
      <Router>
        <div className="main-container">
          {this.state.width > 1000 ? <Sidebar /> : <Topbar />}
          <div className="content-container">
            <Route exact path="/" component={Projects} />
            <Route path="/about" component={About} />
            <Route path="/photography/zagreb" component={Zagreb} />
            <Route path="/photography/ljubljana" component={Ljubljana} />
            <Route path="/photography/atms" component={Atms} />
            <Route path="/photography/people" component={People} />
            <Route path="/photography/landscape" component={Landscape} />
            <Route path="/photography/vrhovci" component={Vrhovci} />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
